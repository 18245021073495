<template>
    <div class="cart-wrapper">
        <search />
        <template v-if="dataList.length">
            <div class="content w1200">
                <ul class="header-box flex-bt">
                    <li v-for="(item,index) in config" :key="index" :class="[item.class,'flex-start']">
                        <span
                            @click="toggleAllCheckButton"
                            v-if="item.icon"
                            class="choose-box flex-center"
                            :class="[response.submit.data.field.is_all_checked ? 'active': '']"
                        >
                            <i class="iconfont">&#xebe6;</i>
                        </span>
                        {{$t(item.label)}}
                    </li>
                </ul>
            </div>
            <div class="w1200">
                <ul class="cart">
                    <li class="items flex-bt" v-for="(item,index) in dataList" :key="index">
                        <div class="child w3 flex-start">
                            <span
                                @click="checked(index)"
                                class="choose-box flex-center"
                                :class="[item.is_checked ? 'active': '']"
                            >
                                <i class="iconfont">&#xebe6;</i>
                            </span>
                        </div>
                        <div class="child w30 flex-start">
                            <img :src="item.goods_image" class="goods-image" />
                            <div class="title">
                                <h6>{{translateGoodsName(item.goods_name)}}  - {{item.color}}, {{item.location}}, {{item.size}}</h6>
                                <span>{{$t('Specification')}}：{{item.goods_sku_text ? item.goods_sku_text : '默認'}}</span>
                            </div>
                        </div>
                        
                        
                        <template v-if="currency === 'USD'">
                            <div class="child w15 flex-start">US${{item.us_price}}</div>
                        </template>
                        <template v-else>
                            <div class="child w15 flex-start">{{item.shop_price}}</div>
                        </template>
                        <div class="child w15 flex-start">
                            <el-input-number size="mini" @change="numberChange(item,'add')" v-model="item.goods_number"></el-input-number>
                        </div>
                        <template v-if="currency === 'USD'">
                            <div class="child w15 flex-start">
                                US${{item.us_price * item.goods_number}}
                            </div>
                        </template>
                        <template v-else>
                            <div class="child w15 flex-start">
                                {{item.shop_price * item.goods_number}}
                            </div>
                        </template>
                        <div class="child w15 flex-start pointer" @click="deleteItem(item)">
                            {{$t('delete')}}
                        </div>
                    </li>
                </ul>
                <div class="buy flex-bt">
                    <div class="left">{{$t('selected')}} {{response.submit.data.field.total_goods}} {{$t('items')}}</div>
                    <div class="right flex-end">
                        <template v-if="currency === 'USD'">
                            <p>, {{$t('total')}}：<span>US${{response.submit.data.field.total_us_price}}</span></p>
                        </template>
                        <template v-else>
                            <p>, {{$t('total')}}：<span>{{response.submit.data.field.total_price}}</span></p>
                        </template>
                        <div class="btn" @click="pay">{{$t('To settle')}}</div>
                    </div>
                </div>
            </div>
        </template>
        <el-empty v-else :description="$t('NO DATA')"></el-empty>
    </div>
</template>
<script>
import Search from "@/components/Search"
//  import translations from './translations.json';
import translations from "@/assets/translations.json";
export default {
    name: "Cart",
    components: { Search },
    data() {
        return {
            config: [
                { label: "select all", icon: true, class: "w15" },
                { label: "product info", class: "w30" },
                { label: "price", class: "w15" },
                { label: "quantity", class: "w15" },
                { label: "amount", class: "w15" },
                { label: "operate", class: "w15" }
            ],
            checkedAll: true,
            allMoney: 0,
            allNUmber: 0,
            dataList: [],
            invalidList: [],
            response: '',
            currency: window.localStorage.getItem('currency'),
            locale: window.localStorage.getItem('locale') || this.$i18n.locale,
        };
    },
    created() {
        this.getDataList()
    },
    methods: { 
        translateGoodsName(goodsName) {
            if (this.locale === 'EN') {
                return translations[goodsName] || goodsName; // Use translations from JSON
            }
            return goodsName; // Return original name if locale is not 'EN'
        },
        pay() {
            this.$router.push({ name: 'orderConfirm' })
        },
        async getDataList() {
    const res = await this.$http.get('/cart', {
        uniacid: window.localStorage.getItem('uniacid')
    })
    if (res.code !== 0) {
        if (res.data.code === 401) {
            window.location.href = '/#/login'
            return false
        }

        let errorMessage = res.message;

        if (this.locale.toUpperCase() === 'EN') {
            if (errorMessage.includes('請先登錄')) {
                errorMessage = 'Please login first';
            }
            // Add more translations as needed
        }

        return this.$message.error(errorMessage);
    }

    let data = res.data;
    let dataList = [];
    let invalidList = [];
    data.items.data.list.forEach(item => {
        if (item.is_invalid) {
            invalidList.push(item);
        } else {
            dataList.push(item);
        }
    });
    this.dataList = dataList;
    this.invalidList = invalidList;
    this.response = data;

    console.log("this.dataList");
    console.log(this.dataList);
},

async editCartGoods(goods) {
    const res = await this.$http.post('/cart/edit', {
        goods_id: goods.goods_id,
        goods_sku_key: goods.goods_sku_key,
        goods_number: goods.goods_number,
        is_checked: goods.is_checked,
        is_direct: 0,
    })
    if (res.code !== 0) {
        let errorMessage = res.message;

        if (this.locale.toUpperCase() === 'EN') {
            if (errorMessage.includes('請先登錄')) {
                errorMessage = 'Please login first';
            }
            // Add more translations as needed
        }

        return this.$message.error(errorMessage);
    }
    this.getDataList();
},

        numberChange(goods, type = 'reduce') {
            this.editCartGoods(goods, type)
        },

        checked(index) {
            this.dataList[index].is_checked = !this.dataList[index].is_checked
            this.editCartGoods(this.dataList[index])
        },

        async toggleAllCheckButton() {
            const res = await this.$http.post('/cart/changeSelectStatus', {
                status: this.response.submit.data.field.is_all_checked ? 0 : 1
            })
            if (res.code !== 0) return this.$message.error(res.message)
            this.getDataList()
        },

        async deleteItem(goods) {
            const res = await this.$http.post('/cart/delete', {
                cart_id: goods.cart_id
            })
            if (res.code !== 0) {
                return this.$message.error(res.message)
            }
            this.getDataList()
        },

        isCheckedAll() {
            this.checkedAll = this.cartList.every(item => item.checked == true)
            this.allMoney = 0
            this.allNUmber = this.cartList.reduce((val, item, index) => {
                item.checked == true && (this.allMoney = this.allMoney + (item.shop_price * item.goods_number))
                item.checked == true && (val += item.goods_number)
                return val
            }, 0)
        },
    }
};
</script>
<style lang="scss" scoped>
.cart-wrapper {
    .pointer{
         cursor: pointer;
    }
    .buy{
        width: 100%;
        height: 82px;
        background: #f2f2f2;
        margin-top: 60px;
        padding-left: 30px;
        margin-bottom: 30px;
        box-sizing: border-box;
        .right{
            span{
                font-size: 22px;
                font-weight: 700;
                color: #e93323!important;
            }
            .btn{
                width: 160px;
                height: 82px;
                text-align: center;
                line-height: 82px;
                font-size: 18px;
                color: #fff;
                margin-left: 30px;
                background: #e93323!important;
            }
        }
    }
    .choose-box {
        width: 18px;
        height: 18px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
        transition: all 0.4s;
        i {
            font-size: 12px;
            display: none;
            transition: all 0.8s;
        }
        &.active {
            background: #e93323;
            border-color: #e93323;
            i {
                display: block;
                color: #fff;
            }
        }
    }
    .content {
        height: 54px;
        background: #f2f2f2;
        margin-top: 20px;
        .header-box {
            li {
                box-sizing: border-box;
                flex: 1;
                height: 100%;
                line-height: 54px;
                padding-left: 20px;
                color: #282828;
                font-size: 14px;
                font-family: "PingFang SC", "Helvetica Neue", Helvetica,
                    "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑,Arial",
                    "sans-serif";
                &.w3 {
                    width: 8%;
                    flex: auto;
                }
                &.w30 {
                    width: 36%;
                    flex: auto;
                }
                &.w15 {
                    width: 18%;
                    flex: auto;
                }
            }
        }
    }
    .cart {
        width: 100%;
        border: 2px solid #f2f2f2;
        border-top: 0;
        box-sizing: border-box;
        .items {
            width: 100%;
            height: 170px;
            padding-left: 20px;
            border-bottom: 2px solid #f2f2f2;
            box-sizing: border-box;
            &:last-child {
                border: none;
            }
            .child {
                height: 100%;
                box-sizing: border-box;
            }
            .goods-image {
                width: 90px;
                height: 90px;
                margin-right: 14px;
            }
            .title {
                flex: 1;
                // height: 90px;
                h6 {
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                }
                span {
                    margin-top: 10px;
                    font-size: 12px;
                    color: #d0d0d0;
                }
            }
            .child {
                &.w3 {
                    width: 8%;
                    flex: auto;
                }
                &.w30 {
                    width: 36%;
                    flex: auto;
                }
                &.w15 {
                    width: 18%;
                    flex: auto;
                }
                
            }
        }
    }
}
</style>